<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    style="width:100%;"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    style="width:100%;"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"
                    v-model:vendor_id="state.vendor_id"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_TSO, ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_CRM_ATSALESMAN])"
                :xl="6" :md="8" :sm="12">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterSalesmanV2
                    style="width:100%;"
                    mode="multiple"
                    v-model:value="state.params.salesman"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip title="Approve">
                        <AButton
                            :loading="state.isActionApprove"
                            @click="btnActionApprove"
                            type="primary">
                            <span class="fa fa-check mr-2" aria-hidden="true"/> Approve
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Approve-Jadwal-Kunjungan"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="id"
                size="small"
                :row-selection="{ selectedRowKeys: state.selectedRowKeys, onChange: onSelectChange }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #status="{ text }">
                    <ATag v-if="text" color="#108ee9">Approval</ATag>
                    <ATag v-else color="grey">Pending</ATag>
                </template>

            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import apiClient from '@/services/axios'
import { Modal } from 'ant-design-vue'
import FilterToko from '@/components/filter/FilterToko'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterSalesmanV2 from '@/components/filter/FilterSalesmanV2'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { includes } from 'lodash'
import moment from 'moment'
import localStorage from 'store'
import {
    hasRoles,
    ROLE_TSO,
    ROLE_ADMIN_BK,
    ROLE_TIM_CRM,
    ROLE_CRM_ATSALESMAN,
    ROLE_DISTRIBUTOR,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterToko,
        FilterDistributor,
        FilterSalesmanV2,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'kode_toko',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Nopeg Salesman',
                    dataIndex: 'nopeg_salesman',
                },
                {
                    title: 'Nama Salesman',
                    dataIndex: 'nama_salesman',
                },
                {
                    title: 'Bulan',
                    dataIndex: 'bulan',
                },
                {
                    title: 'Jadwal',
                    dataIndex: 'jadwal',
                },
                {
                    title: 'Status',
                    dataIndex: 'approve',
                    slots: { customRender: 'status' },
                },
            ],
            endpoint: {
                current: '/api/visit-plant/list-approval',
                approve: '/api/visit-plant/approve',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                salesman: [],
                toko: [],
                start_date: null,
                end_date: null,
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
            selectedRows: [],
            selectedRowKeys: [],
            vendor_id: null,
        })

        const onSelectChange = (keys, rows) => {
            state.selectedRowKeys = keys
            state.selectedRows = rows
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const disabledStartDate = startValue => {
            if (!startValue || !state.params.end_date) {
                return false
            }
            return startValue.valueOf() > state.params.end_date.valueOf()
        }

        const disabledEndDate = endValue => {
            if (!endValue || !state.params.start_date) {
                return false
            }
            return state.params.start_date.valueOf() >= endValue.valueOf()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isSync = false
                        // hide button Sync in action column except admin
                        item.role_sync = hasRoles(ROLE_ADMIN_BK)
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnActionApprove = () => {
            if (state.selectedRowKeys.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            Modal.confirm({
                title: 'Konfirmasi Approve',
                content: 'Apakah Anda ingin approve?',
                onOk() {
                    apiClient.post(state.endpoint.current, {
                        approve: state.selectedRows.map(item => item.id),
                    })
                },
                onCancel() { },
            })
        }

        // handle vue
        onMounted(() => {
            if (hasRoles([ROLE_DISTRIBUTOR])) {
                state.vendor_id = user.value.vendor_id
            }

            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            queryParams,
            handleTableChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            onSelectChange,
            btnActionApprove,
            // only roles
            hasRoles,
            ROLE_TSO,
            ROLE_ADMIN_BK,
            ROLE_TIM_CRM,
            ROLE_CRM_ATSALESMAN,
            ROLE_DISTRIBUTOR,
        }
    },
})
</script>
